import PropTypes from 'prop-types'
import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const Hero = ({
  title,
  image,
  light,
  color,
  titleClassName,
  children,
}) => {
  const isLight = light ? 'is-light' : 'is-dark'
  const backgroundColor = light ? '#ffffff' : '#363636'
  const titleStyle = color ? { color } : {}
  const heroHeight = 'is-fullheight-with-navbar'
  return (
    <BackgroundImage
      Tag="section"
      className={`hero ${isLight} ${heroHeight}`}
      fluid={image}
      backgroundColor={backgroundColor}
    >
      {children}
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1
            className={`title is-1 is-family-secondary ${titleClassName}`}
            style={titleStyle}
          >
            {title}
          </h1>
        </div>
      </div>
    </BackgroundImage>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  light: PropTypes.bool,
  color: PropTypes.string,
  image: PropTypes.any,
  titleClassName: PropTypes.string,
}

Hero.defaultProps = {
  title: '',
  light: true,
  color: null,
  titleClassName: '',
}

export default Hero
