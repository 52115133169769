// eslint-disable-next-line
import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import {useIntl} from 'gatsby-plugin-intl'

const SingleTemplate = props => {
  console.log('POST PROPS', props)
  const t = useIntl().formatMessage
  const post = {
    ...props.data.post,
    frontmatter: { ...props.data.post.original.frontmatter, ...props.data.post.frontmatter },
  }
  const siteTitle = props.data.site.siteMetadata.title
  const siteDescription = post.excerpt
  const hasImage = !!post.frontmatter.image
  const image = hasImage ? post.frontmatter.image.childImageSharp.fluid : null
  const notTranslated = post.frontmatter.lang !== props.pageContext.language

  return (
    <Layout subTitle={post.frontmatter.title} >
      <Helmet
        meta={[{ name: 'description', content: siteDescription }]}
      />
      {hasImage ? (
        <Hero
          image={image}
          title={post.frontmatter.title}
          light={post.frontmatter.light}
          color={post.frontmatter.color}
        />
      ) : null}
      <section className="section page-content">
        <div className="container article-header has-text-centered">
          {!hasImage && <h1 className="title is-1">{post.frontmatter.title}</h1>}
          {post.frontmatter.date && <em className="is-size-6 has-text-grey">{post.frontmatter.date}</em>}
        </div>
        <main className="container content-container">
          {notTranslated && <div className="notification">{t({ id: 'article.notTranslated' })}</div>}
          <div className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
        </main>
      </section>
    </Layout>
  )
}

export default SingleTemplate

export const pageQuery = graphql`
  query SinglePageById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: markdownRemark(id: { eq: $id }) {
      id
      excerpt
      html
      frontmatter {
        title
        translatedTags
        lang
      }
      original {
        frontmatter {
          date(formatString: "LL")
          light
          color
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
